<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-width="$root.rwd < 3 ? '120px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'left'">
		<br>
		<el-form-item label-width="0">
			<b class="theme_text">年度捐款1000元(含)以上。您可選擇⼀次贊助１年或一次贊助２年以上。</b>
		</el-form-item>
		<el-form-item label="年度捐款金額" prop="amount_per_year">
			<b class="theme_text">NT${{"\u3000"}}</b>
			<el-input-number v-model.number="ruleForm.amount_per_year" :min="1000" @focus.once="checkMembership()" />
		</el-form-item>
		<el-form-item label="共捐助" prop="donation_years">
			<el-input-number v-model.number="ruleForm.donation_years" :min="1" @focus.once="checkMembership()" />
			<b class="theme_text">{{"\u3000"}}年</b>
		</el-form-item>
		<el-form-item label="捐款⾦額總計">
			<b>{{(ruleForm.amount_per_year && ruleForm.donation_years && ruleForm.amount_per_year * ruleForm.donation_years || 0) | currencyFilter}}</b>
		</el-form-item>
		<br>
		<el-form-item label-width="0">
			<b class="theme_text">捐款收據可作為年度申報所得稅列舉扣除之憑證{{"\u3000"}}敬請費心填寫。</b>
		</el-form-item>
		<el-form-item>
			<el-checkbox v-model="checked" @change="handleCheck" class="theme_text">同捐款人</el-checkbox>
		</el-form-item>
		<el-form-item label="抬頭" prop="title">
			<el-input v-model="ruleForm.title" class="round" :disabled="checked" @focus.once="checkMembership()" />
		</el-form-item>
		<el-form-item label="身分證／統編" prop="identity">
			<el-input v-model="ruleForm.identity" class="round" @focus.once="checkMembership()" />
		</el-form-item>
		<el-form-item label="地址">
			<div class="row-grid" gutter-x="10">
				<el-form-item prop="recipient_zipcode">
					<el-input v-model="ruleForm.recipient_zipcode" :disabled="checked" placeholder="郵遞區號" style="width:auto;" class="round" />
				</el-form-item>
				<el-form-item prop="recipient_city">
					<el-select v-model="ruleForm.recipient_city" :disabled="checked" placeholder="縣市" class="round" @change="ruleForm.recipient_district = ''">
						<template v-for="(val, key, i) in administrativeDistrict">
							<el-option :value="key" :key="i" />
						</template>
					</el-select>
				</el-form-item>
				<el-form-item prop="recipient_district">
					<el-select v-model="ruleForm.recipient_district" :disabled="checked" placeholder="鄉鎮市區" class="round">
						<template v-for="(district, i) in (administrativeDistrict[ruleForm.recipient_city] || new Array)">
							<el-option :value="district" :key="i" />
						</template>
					</el-select>
				</el-form-item>
			</div>
			<el-form-item prop="recipient_address">
				<el-input v-model="ruleForm.recipient_address" :disabled="checked" placeholder="里(村)/路(街)/號/樓(室)" class="round" />
			</el-form-item>
		</el-form-item>
		<el-form-item label-width="0">
			<br>
			<b class="theme_text">金枝將在相關出版品中刊登名銜感謝您的捐款，如不方便，請勾選下行。</b>
			<br>
			<el-checkbox v-model="ruleForm.anonymous">我樂於捐款，但盼不公開姓名，請以「隱名氏」呈現。</el-checkbox>
		</el-form-item>
	</el-form>
</template>

<script>
import administrativeDistrict from "@/assets/data/administrative-district.json";
import { rule } from "@/utils/shared-methods";
import { mapState } from "vuex";
export default {
	data() {
		return {
			administrativeDistrict,
			ruleForm: new Object,
			rules: {
				amount_per_year: [
					{ required: true, message: "年度捐款金額", trigger: "blur" },
					{ type: "number", message: "請填數字", trigger: "blur" },
				],
				donation_years: [
					{ required: true, message: "共捐助Ｎ年", trigger: "blur" },
					{ type: "number", message: "請填數字", trigger: "blur" },
				],
			},
			checked: false,
		}
	},
	computed: {
		...mapState("auth", ["_auth"])
	},
	methods: {
		handleCheck(val) {
			if (val) this._.assign(this.ruleForm, {
				title: this._auth.name,
				recipient_zipcode: this._auth.zipcode,
				recipient_city: this._auth.city,
				recipient_district: this._auth.district,
				recipient_address: this._auth.address,
			})
		},
		async validate() {
			return new Promise((resolve, reject) => {
				this.$refs["ruleForm"].validate((valid, object) => {
					if (valid) resolve(this.ruleForm);
					else reject("請確認表格皆填寫完畢");
				});
			});
		},
		resetFields() {
			this.ruleForm = new Object;
			this.$refs["ruleForm"].resetFields();
		},
		checkMembership() {
			try {
				if (this._auth) {
					this.$set(this.$data, "ruleForm", this._auth);
					const { roles } = this._auth;
					if (roles.includes("donor")) throw "您已是椅友會員";
					else if (!roles.includes("member")) throw `請先加入「<a href="/join/member" style="color:#a98a4c;"><u>金粉會員</u></a>」`;
				} else throw `請先「<a href="/login" style="color:#a98a4c;"><u>登入</u></a>」或者加入「<a href="/join/member" style="color:#a98a4c;"><u>金粉會員</u></a>」`;
			} catch (error) {
				this.$alert(error, {
					type: "warning",
					confirmButtonText: "知道了",
					dangerouslyUseHTMLString: true
				});
			}
		}
	},
	created() {
		this.checkMembership()
	}
}
</script>